<template>
	<GridButton
		:data-element-ref="elementId"
		:tag-name="tagName"
		:href="href"
		:target="target"
		:rel="rel"
		:type="type"
		:content="content"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-width="borderWidth"
		:border-radius="borderRadius"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
		:mobile-element-width="mobileElementWidth"
		:mobile-element-height="mobileElementHeight"
		@click.prevent
		@drag.prevent
		@dragstart.prevent
	/>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';

type Props = {
	elementId?: string;
	data: Record<string, string | number>;
}

const props = defineProps<Props>();
const { getButtonHref } = useSiteGlobal();
const {
	tagName,
	href,
	target,
	rel,
	type,
	content,
	fontSizeMobile,
	fontSizeDesktop,
	fontFamily,
	fontWeight,
	borderWidth,
	borderRadius,
	backgroundColor,
	fontColor,
	borderColor,
	backgroundColorHover,
	fontColorHover,
	borderColorHover,
	mobileElementWidth,
	mobileElementHeight,
} = useGridButton(props, {
	href: computed(() => getButtonHref({
		isFormButton: false,
		linkedPageId: props.data.linkedPageId,
		linkType: props.data.linkType,
		href: props.data.href,
	})),
});
</script>
