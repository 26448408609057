import {
	ref,
	computed,
} from 'vue';

const isTriggerIntersecting = ref(true);

export const useStickyTrigger = () => {
	const setIntersectingState = (isIntersecting) => {
		isTriggerIntersecting.value = isIntersecting;
	};

	return {
		// when trigger is NOT intersecting, it means user has scrolled
		hasUserScrolled: computed(() => !isTriggerIntersecting.value),
		setIntersectingState,
	};
};
