const SITES_API = `${(import.meta as any).env.VITE_BACKEND_API_URL}/u1/sites`;

export default {
	verifyPagePassword: async ({
		siteId,
		pageId,
		locale,
		password,
	}: {
		siteId: string;
		pageId: string;
		locale: string;
		password: string;
	}) => fetch(`${SITES_API}/verify-page-password`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			siteId,
			pageId,
			language: locale,
			password,
		}),
	}),
};
