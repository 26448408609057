<script setup lang="ts">
import {
	EcommerceProductImage,
	ImageRatioOption,
} from '@zyro-inc/site-modules/types';
import { moveDirection } from '@zyro-inc/site-modules/utils/moveDirection';
import ProductMediaElement from '@zyro-inc/site-modules/components/ecommerce/ProductMediaElement.vue';
import { ECOMMERCE_PRODUCT_PLACEHOLDERS_BASE_PATH } from '@zyro-inc/cdn-builder-placeholders/constants';

import {
	computed,
	onBeforeUnmount,
	onMounted,
	ref,
	toRef,
	watch,
} from 'vue';

import {
	PRODUCT_IMAGE_MAX_WIDTH_PX_DEFAULT,
	PRODUCT_IMAGE_MAX_WIDTH_PX_LEFT,
	PRODUCT_IMAGE_MAX_WIDTH_PX_PREVIEW,
	PRODUCT_IMAGE_MAX_WIDTH_SMALL_PX,
} from '@zyro-inc/site-modules/constants';

const PRODUCT_PLACEHOLDER_IMAGE = `${ECOMMERCE_PRODUCT_PLACEHOLDERS_BASE_PATH}/placeholder.png`;

interface Props {
	mediaItems: EcommerceProductImage[];
	productTitle: string;
	arrowsColor?: string;
	navigationThumbnailArrowsColor?: string;
	galleryPlacement?: string;
	imageRatio?: string;
	imageBorderRadius?: string;
	isEager?: boolean;
	variantImage?: string | null;
	isQuickPreview?: boolean;
	isVariantImagePreselected?: boolean;
	siteId?: string;
}

const props = withDefaults(defineProps<Props>(), {
	arrowsColor: 'unset',
	navigationThumbnailArrowsColor: 'unset',
	galleryPlacement: 'left',
	imageRatio: 'unset',
	imageBorderRadius: 'unset',
	isEager: false,
	variantImage: null,
	isQuickPreview: false,
	isVariantImagePreselected: false,
	siteId: '',
});

defineEmits<{
	'image-click': [number],
}>();

const placeholderImageData = {
	url: PRODUCT_PLACEHOLDER_IMAGE,
	type: 'image',
};
const carouselRef = ref();
const windowWidth = ref<number>(0);
const slideDirection = ref<string>('');
const currentIndex = ref(0);
const imageListStartIndex = ref(0);
const mediaItems = toRef(() => (props.mediaItems.length ? props.mediaItems : [placeholderImageData]));

const isGalleryLeft = computed(() => props.galleryPlacement === 'left');
const imagePreviewAmount = computed(() => {
	let amount = 0;

	switch (true) {
	case windowWidth.value < (isGalleryLeft.value ? 920 : 900):
		amount = 4;
		break;
	case windowWidth.value < (isGalleryLeft.value ? 1060 : 1020):
		amount = 5;
		break;
	case windowWidth.value < (isGalleryLeft.value ? 1250 : 1160):
		amount = 6;
		break;
	case windowWidth.value < (isGalleryLeft.value ? 1400 : 1320):
		amount = 7;
		break;
	default:
		amount = isGalleryLeft.value ? 7 : 8;
	}

	if (imageListStartIndex.value !== 0 && imageListStartIndex.value + amount < props.mediaItems.length) {
		return amount - 1;
	}

	return amount;
});
const thumbnails = computed(() => props.mediaItems.slice(imageListStartIndex.value, imageListStartIndex.value + imagePreviewAmount.value));
const isThumbnailVisible = computed(() => thumbnails.value.some((image) => image.url === props.mediaItems[currentIndex.value].url));
const isMoreThanOneImage = computed(() => props.mediaItems.length > 1);
const isArrowsShown = computed(() => isMoreThanOneImage.value && !props.isQuickPreview);
const isRightImageArrowShown = computed(() => (props.mediaItems.length < imagePreviewAmount.value
	? false
	: ((imageListStartIndex.value + imagePreviewAmount.value) < props.mediaItems.length)));
const objectFit = computed(() => {
	switch (props.imageRatio) {
	case ImageRatioOption.COVER:
	case ImageRatioOption.LANDSCAPE:
	case ImageRatioOption.PORTRAIT:
		return 'cover';
	default:
		return 'contain';
	}
});
const imageMaxWidth = computed(() => (props.isQuickPreview ? PRODUCT_IMAGE_MAX_WIDTH_PX_PREVIEW : PRODUCT_IMAGE_MAX_WIDTH_PX_DEFAULT));
const imageMaxHeight = computed(() => {
	const width = isGalleryLeft.value ? PRODUCT_IMAGE_MAX_WIDTH_PX_LEFT : imageMaxWidth.value;

	switch (props.imageRatio) {
	case ImageRatioOption.LANDSCAPE:
		return width * 0.75;
	case ImageRatioOption.PORTRAIT:
		return width * 1.333;
	default:
		// eslint-disable-next-line unicorn/no-useless-undefined
		return undefined;
	}
});

const carouselStyle = computed(() => ({
	'--image-max-width': `${imageMaxWidth.value}px`,
	'--image-max-width-small': `${PRODUCT_IMAGE_MAX_WIDTH_SMALL_PX}px`,
	'--image-object-fit': objectFit.value,
	'--image-border-radius': objectFit.value === 'cover' ? props.imageBorderRadius : 0,
	'--arrow-color': props.arrowsColor,
	'--thumbnail-arrow-color': props.navigationThumbnailArrowsColor,
}));

const presetThumbnailPosition = () => {
	if (props.mediaItems.length - currentIndex.value < imagePreviewAmount.value) {
		imageListStartIndex.value = props.mediaItems.length - imagePreviewAmount.value;
	} else {
		imageListStartIndex.value = currentIndex.value;
	}
};

const resizeEventHandler = () => {
	windowWidth.value = window.innerWidth;

	if (imagePreviewAmount.value < props.mediaItems.length) {
		presetThumbnailPosition();
	}
};

const isImageMatch = (thumbnailIndex: number) => thumbnails.value[thumbnailIndex].url === props.mediaItems[currentIndex.value].url;
const handleThumbnailClick = (thumbnailIndex: number) => {
	currentIndex.value = props.mediaItems.findIndex((image) => image.url === thumbnails.value[thumbnailIndex].url);
};

const goToNextSlide = () => {
	slideDirection.value = 'right';
	currentIndex.value = currentIndex.value === props.mediaItems.length - 1 ? 0 : currentIndex.value + 1;

	if (!isThumbnailVisible.value) {
		if (imageListStartIndex.value + imagePreviewAmount.value === props.mediaItems.length) {
			imageListStartIndex.value = 0;
		} else {
			presetThumbnailPosition();

			if (imagePreviewAmount.value !== thumbnails.value.length) {
				presetThumbnailPosition();
			}
		}
	}
};

const goToPreviousSlide = () => {
	slideDirection.value = 'left';
	currentIndex.value = currentIndex.value === 0 ? props.mediaItems.length - 1 : currentIndex.value - 1;

	if (!isThumbnailVisible.value) {
		if (imageListStartIndex.value === 0) {
			imageListStartIndex.value = props.mediaItems.length - imagePreviewAmount.value;
		} else {
			presetThumbnailPosition();
		}
	}
};

const moveImageListRight = () => {
	const beforeAmount = imagePreviewAmount.value;

	imageListStartIndex.value += 1;

	const afterAmount = imagePreviewAmount.value;

	if (beforeAmount !== afterAmount && isRightImageArrowShown.value) {
		moveImageListRight();
	}
};

const moveImageListLeft = () => {
	imageListStartIndex.value -= 1;
};

const enableCarouselMoveEvents = (isEnabled: boolean) => {
	if (!isMoreThanOneImage.value) {
		return;
	}

	const { enableMoveEvents } = moveDirection({
		move: {
			drag: false,
			swipe: true,
		},
		onMoveLeft: goToNextSlide,
		onMoveRight: goToPreviousSlide,
	});

	enableMoveEvents(isEnabled, carouselRef.value);
};

const setVariantImage = () => {
	const index = props.mediaItems.findIndex((image) => image.url === props.variantImage);

	if (index > -1) {
		currentIndex.value = index;
	}
};

watch(() => props.variantImage, () => {
	setVariantImage();

	if (!isThumbnailVisible.value) {
		presetThumbnailPosition();
	}
});

onMounted(() => {
	windowWidth.value = window.innerWidth;
	window.addEventListener('resize', resizeEventHandler);

	if (props.isVariantImagePreselected) {
		setVariantImage();
	}

	enableCarouselMoveEvents(true);
});

onBeforeUnmount(() => {
	window.removeEventListener('resize', resizeEventHandler);

	enableCarouselMoveEvents(false);
});
</script>

<template>
	<div
		class="product-carousel"
		:class="{ 'product-carousel--left': isGalleryLeft }"
		:style="carouselStyle"
	>
		<div
			ref="carouselRef"
			:class="`
				product-carousel__image-wrapper
				product-carousel__image-wrapper--${imageRatio}${isGalleryLeft ? '-left' : ''}
			`"
		>
			<template v-if="isArrowsShown">
				<button
					v-qa="`product-carousel-button-prev`"
					class="product-carousel__slider-button product-carousel__slider-button--left"
					@click="goToPreviousSlide"
				>
					<span class="product-carousel__arrow product-carousel__arrow--left" />
				</button>
				<button
					v-qa="`product-carousel-button-next`"
					class="product-carousel__slider-button product-carousel__slider-button--right"
					@click="goToNextSlide"
				>
					<span class="product-carousel__arrow product-carousel__arrow--right" />
				</button>
			</template>
			<Transition
				:name="`slide-${slideDirection}`"
				mode="out-in"
				class="product-carousel__image-content"
			>
				<ProductMediaElement
					:key="mediaItems[currentIndex].url"
					:src="mediaItems[currentIndex].url"
					:media-type="mediaItems[currentIndex].type"
					:alt="productTitle"
					class="product-carousel__image product-carousel__main-image"
					:is-eager="isEager"
					:object-fit="objectFit"
					:width="imageMaxWidth"
					:height="imageMaxHeight"
					enable-srcset
					@click="$emit('image-click', currentIndex)"
				/>
			</Transition>
		</div>
		<template v-if="isMoreThanOneImage">
			<div
				v-if="!isQuickPreview"
				class="product-carousel__image-list"
				:class="{ 'product-carousel__image-list-left': isGalleryLeft }"
			>
				<button
					v-if="imageListStartIndex !== 0"
					v-qa="`product-carousel-image-button-left`"
					class="product-carousel__image-arrow"
					:class="{
						'product-carousel__image-arrow--left': !isGalleryLeft,
						'product-carousel__image-arrow--top': isGalleryLeft
					}"
					@click="moveImageListLeft"
				/>
				<div :class="{ 'product-carousel__image-list-element-wrapper-left': isGalleryLeft }">
					<button
						v-for="(image, index) in thumbnails"
						:key="`image-${index}-${image.url}`"
						class="product-carousel__image-list-element"
						:class="{ 'product-carousel__image-list-element--active': isImageMatch(index) }"
						@click="handleThumbnailClick(index)"
					>
						<ProductMediaElement
							:src="image.url"
							:alt="productTitle"
							:media-type="image.type"
							class="product-carousel__image"
							:is-eager="isEager"
							:object-fit="imageRatio"
							is-video-autoplay-disabled
							:site-id="siteId"
							:width="PRODUCT_IMAGE_MAX_WIDTH_SMALL_PX"
							:height="PRODUCT_IMAGE_MAX_WIDTH_SMALL_PX"
						/>
					</button>
				</div>
				<button
					v-if="isRightImageArrowShown"
					v-qa="`product-carousel-image-button-right`"
					class="product-carousel__image-arrow"
					:class="{
						'product-carousel__image-arrow--right': !isGalleryLeft,
						'product-carousel__image-arrow--bottom': isGalleryLeft
					}"
					@click="moveImageListRight"
				/>
			</div>
			<div class="product-carousel__dots-wrapper">
				<button
					v-for="(_, index) in mediaItems"
					:key="`image-dot-${index}`"
					class="product-carousel__dot-button"
					@click="currentIndex = index"
				>
					<span
						class="product-carousel__dot"
						:class="{ 'product-carousel__dot--active': index === currentIndex }"
					/>
				</button>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.product-carousel {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: var(--image-max-width);
	overflow: hidden;

	&--left {
		flex-direction: row-reverse;
	}

	&__image-wrapper {
		position: relative;
		display: flex;
		width: 100%;
		height: 0;
		overflow: hidden;
		transition: height 0.2s ease-in;

		&--portrait {
			padding-bottom: 133.3%;

			&-left {
				padding-bottom: 116%;
			}
		}

		&--landscape {
			padding-bottom: 75%;

			&-left {
				padding-bottom: 66%;
			}
		}

		&--cover,
		&--contain {
			padding-bottom: 100%;

			&-left {
				padding-bottom: 87.33%;
			}
		}

		&--contain, &--contain-left {
			border: 1px solid $color-gray-border;
		}

		// hack to make image a responsive square according to it's width
		&::after {
			display: block;
			padding-bottom: 100%;
			content: "";
		}
	}

	&__image {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}

	&__main-image {
		position: absolute;
		top: 0;
		left: 0;
		cursor: pointer;
		border-radius: var(--image-border-radius, 0);
		object-fit: var(--image-object-fit, cover);
		object-position: center;
	}

	&__image-content {
		z-index: 0;
		flex: 1 0 auto;
		width: 100%;
		overflow: hidden;
		font-size: 2rem;
		font-weight: bold;
		align-self: center;
	}

	&__arrow {
		display: inline-block;
		width: 48px;
		height: 48px;
		border: 1px solid rgba($color-gray-border, 0.2);
		border-radius: 50%;
		-webkit-tap-highlight-color: transparent;

		&::before {
			position: absolute;
			width: 48px;
			height: 48px;
			content: "";
			background-color: var(--arrow-color, $color-gray-border);
			filter: invert(1);
			border-radius: 50%;
			opacity: 0.2;
		}

		&::after {
			position: relative;
			display: inline-block;
			width: 0.7em;
			height: 0.7em;
			margin-top: 18px;
			margin-right: 0.5em;
			content: "";
			border-top: 0.2em solid var(--arrow-color, $color-dark);
			border-right: 0.2em solid var(--arrow-color, $color-dark);
			transform: rotate(225deg);
		}

		&--left {
			&::before {
				margin-top: -1px;
				margin-left: -1px;
			}

			&::after {
				margin-left: 20px;
			}
		}

		&--right {
			&::before {
				margin-top: -1px;
				margin-left: -18px;
			}

			&::after {
				margin-right: 20px;
				transform: rotate(45deg);
			}
		}
	}

	&__slider-button {
		position: absolute;
		z-index: 1;
		box-sizing: content-box;
		width: 75px;
		height: 100%;
		cursor: pointer;
		background-color: transparent;
		border: 0;
		outline: none;
		-webkit-tap-highlight-color: transparent;

		&--left {
			left: 0;
			padding-left: 20px;
			text-align: left;
		}

		&--right {
			right: 0;
			padding-right: 20px;
			text-align: right;

			&::after {
				transform: rotate(45deg);
			}
		}
	}

	&__image-arrow {
		height: 100%;
		cursor: pointer;
		background-color: transparent;
		-webkit-tap-highlight-color: transparent;

		&::after {
			display: inline-block;
			width: 0.7em;
			height: 0.7em;
			content: "";
			border-top: 0.2em solid var(--thumbnail-arrow-color, $color-dark);
			border-right: 0.2em solid var(--thumbnail-arrow-color, $color-dark);
			transform: rotate(225deg);
		}

		&--left {
			padding-left: 16px;
		}

		&--right {
			padding-right: 18px;

			&::after {
				transform: rotate(45deg);
			}
		}

		&--top {
			height: 50px;
			padding-right: 6px;

			&::after {
				transform: rotate(315deg);
			}
		}

		&--bottom {
			height: 40px;
			padding-right: 6px;

			&::after {
				transform: rotate(135deg);
			}
		}
	}

	&__image-list {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;
		margin-right: -1px;
		margin-left: -3px;
	}

	&__image-list-left {
		flex-direction: column;
		margin: -3px 0 -1px;
	}

	&__image-list-element-wrapper-left {
		display: grid;
		padding-right: 6px;
	}

	&__image-list-element {
		width: var(--image-max-width-small);
		height: var(--image-max-width-small);
		padding: 3px;
		margin: 3px;
		cursor: pointer;
		background-color: transparent;
		opacity: 0.65;
		transition: opacity 0.3s ease-in-out;

		&--active {
			border: 1px solid $color-dark;
			opacity: 1;
		}

		&:last-child {
			margin-right: 1px;
			margin-bottom: 1px;
		}
	}

	&__dots-wrapper {
		position: absolute;
		bottom: 16px;
		z-index: 10;
		display: none;
		align-items: center;
		justify-content: center;
		width: 100%;
		-webkit-tap-highlight-color: transparent;
	}

	&__dot-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		cursor: pointer;
		background: transparent;
		border: none;
	}

	&__dot {
		width: 10px;
		height: 10px;
		background-color: var(--arrow-color, $color-dark);
		border-radius: 100%;
		opacity: 0.5;
		transition: opacity 0.3s ease-in-out;

		&:not(:last-child) {
			margin-right: 6px;
		}

		&--active {
			opacity: 1;
		}
	}
}

@include site-engine-mobile {
	.product-carousel {
		max-width: none;

		&--left {
			@media screen and (min-width: 920px) {
				height: unset !important;
			}
		}

		&__image-list {
			display: none;
		}

		&__dots-wrapper {
			display: flex;
		}

		// Need to reset padding-bottom back to original values,
		// because preview images are not visible on mobile
		&__image-wrapper {
			&--portrait-left {
				padding-bottom: 133.3%;
			}

			&--landscape-left {
				padding-bottom: 75%;
			}

			&--cover-left,
			&--contain-left {
				padding-bottom: 100%;
			}
		}
	}
}
</style>
