export const getIsDomainWithWWWPrefix = (domain) => domain.startsWith('www.');
export const getDomainWithoutWWWPrefix = (domain) => (getIsDomainWithWWWPrefix(domain) ? domain.substring(4) : domain);
export const getDomainProperties = (domain) => {
	const domainWithoutWWW = getDomainWithoutWWWPrefix(domain);

	const [domainName, ...tlds] = domainWithoutWWW.split('.');

	return {
		domainName,
		tld: tlds.join('.'),
	};
};

export const getDomain = () => {
	const hostnameSplitted = window.location.hostname.split('.');

	return hostnameSplitted.slice(Math.max(hostnameSplitted.length - 2, 1)).join('.');
};
