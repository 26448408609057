<script setup lang="ts">
import {
	computed,
	ref,
	watch,
	onMounted,
} from 'vue';

import ChatbotConversation from '@zyro-inc/site-modules/components/chatbot/ChatbotConversation.vue';
import ChatbotLoader from '@zyro-inc/site-modules/components/chatbot/ChatbotLoader.vue';
import ChatbotRestartModal from '@zyro-inc/site-modules/components/chatbot/modals/ChatbotRestartModal.vue';
import type {
	ChatbotConversationMessage,
	ChatbotTexts,
} from '@zyro-inc/site-modules/types';

type Props = {
  conversationHistory: ChatbotConversationMessage[];
  texts: ChatbotTexts;
  isResponding: boolean;
  isRestarting: boolean;
  areActionsDisabled: boolean;
  isClosable?: boolean;
  isRestartVisible?: boolean;
  isIconButton?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
	isRestartVisible: true,
	isClosable: true,
	isIconButton: true,
});

type Emits = {
  initialize: [];
  'toggle-chat': [];
  'function-click': [
    { name: string; arguments: Record<string, string> | undefined },
  ];
  respond: [message: string];
  restart: [];
};

const emit = defineEmits<Emits>();

const CHATBOT_CHAR_LIMIT = 1000;
const CHATBOT_CHAR_COUNT_VISIBILITY_THRESHOLD = 900;

const isRestartModalActive = ref(false);
const isInputActive = ref(false);

const inputRef = ref<HTMLElement | null>(null);

const message = ref('');

const characterCount = computed(() => message.value.length);
const chatbotBaseBottomMargin = computed(() => (props.isIconButton ? '4px' : '12px'));

const isCharacterCountShown = computed(
	() => characterCount.value > CHATBOT_CHAR_COUNT_VISIBILITY_THRESHOLD,
);

const isSendButtonDisabled = computed(
	() => !message.value || props.isResponding,
);

const characterCountText = computed(
	() => `${characterCount.value} / ${CHATBOT_CHAR_LIMIT}`,
);

const handleMessageSubmit = () => {
	if (!message.value || !inputRef.value || props.isResponding) {
		return;
	}

	emit('respond', message.value);
	message.value = '';
};

const handleKeyPressEnter = (event: KeyboardEvent) => {
	// if key is shift+enter, add new line, otherwise submit message
	if (event.shiftKey) {
		message.value += '\n';
	} else {
		handleMessageSubmit();
	}
};

const handleInput = (e: any) => {
	message.value = e.target.value;
};

const adjustInputHeight = () => {
	if (inputRef.value) {
		inputRef.value.style.height = 'auto';
		inputRef.value.style.height = message.value.length
			? `${inputRef.value.scrollHeight}px`
			: 'auto';
	}
};

watch(message, () => {
	adjustInputHeight();
});

onMounted(() => {
	if (props.isClosable) return;

	emit('initialize');
});

</script>

<template>
	<div class="chatbot-base">
		<div class="chatbot-header">
			<p class="chatbot-header__text">
				{{ texts.main.title }}
				<span
					v-if="texts.main.betaBadge"
					class="chatbot-header__beta"
				>
					{{ texts.main.betaBadge }}
				</span>
			</p>
			<div class="chatbot-header__actions">
				<div
					v-if="isRestartVisible"
					class="chatbot-header__action-button-wrapper"
				>
					<button
						data-qa="ai-chatbot-restart-button"
						class="chatbot-header__action-button chatbot-button"
						:title="texts.main.tooltipReset"
						@click="isRestartModalActive = true"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="mask0_2974_15572"
								style="mask-type:alpha"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="24"
								height="24"
							>
								<rect
									width="24"
									height="24"
									fill="currentColor"
								/>
							</mask>
							<g mask="url(#mask0_2974_15572)">
								<path
									d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417
                  4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667
                  4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583
                  14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917
                  20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55
                  18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM10 17C10.2833
                  17 10.5208 16.9042 10.7125 16.7125C10.9042 16.5208 11 16.2833 11 16V9C11 8.71667 10.9042 8.47917
                  10.7125 8.2875C10.5208 8.09583 10.2833 8 10 8C9.71667 8 9.47917 8.09583 9.2875 8.2875C9.09583
                  8.47917 9 8.71667 9 9V16C9 16.2833 9.09583 16.5208 9.2875 16.7125C9.47917 16.9042 9.71667 17 10 17ZM14
                  17C14.2833 17 14.5208 16.9042 14.7125 16.7125C14.9042 16.5208 15 16.2833 15 16V9C15 8.71667 14.9042
                  8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8C13.7167 8 13.4792 8.09583 13.2875 8.2875C13.0958
                   8.47917 13 8.71667 13 9V16C13 16.2833 13.0958 16.5208 13.2875 16.7125C13.4792 16.9042 13.7167 17 14 17Z"
									fill="currentColor"
								/>
							</g>
						</svg>
					</button>
					<span class="chatbot-header__action-button-tooltip">
						{{ texts.main.tooltipReset }}
					</span>
				</div>

				<div
					v-if="isClosable"
					class="chatbot-header__action-button-wrapper"
				>
					<button
						class="chatbot-header__action-button chatbot-button"
						:title="texts.main.tooltipClose"
						@click="emit('toggle-chat')"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="mask0_2974_15576"
								style="mask-type:alpha"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="24"
								height="24"
							>
								<rect
									width="24"
									height="24"
									fill="currentColor"
								/>
							</mask>
							<g mask="url(#mask0_2974_15576)">
								<path
									d="M12 13.3998L7.10005 18.2998C6.91672 18.4831 6.68338 18.5748 6.40005 18.5748C6.11672
                  18.5748 5.88338 18.4831 5.70005 18.2998C5.51672 18.1165 5.42505 17.8831 5.42505 17.5998C5.42505
                  17.3165 5.51672 17.0831 5.70005 16.8998L10.6 11.9998L5.70005 7.0998C5.51672 6.91647 5.42505 6.68314
                   5.42505 6.3998C5.42505 6.11647 5.51672 5.88314 5.70005 5.6998C5.88338 5.51647 6.11672 5.4248 6.40005
                   5.4248C6.68338 5.4248 6.91672 5.51647 7.10005 5.6998L12 10.5998L16.9 5.6998C17.0834 5.51647 17.3167
                   5.4248 17.6 5.4248C17.8834 5.4248 18.1167 5.51647 18.3 5.6998C18.4834 5.88314 18.575 6.11647 18.575
                   6.3998C18.575 6.68314 18.4834 6.91647 18.3 7.0998L13.4 11.9998L18.3 16.8998C18.4834 17.0831 18.575
                    17.3165 18.575 17.5998C18.575 17.8831 18.4834 18.1165 18.3 18.2998C18.1167 18.4831 17.8834 18.5748
                    17.6 18.5748C17.3167 18.5748 17.0834 18.4831 16.9 18.2998L12 13.3998Z"
									fill="currentColor"
								/>
							</g>
						</svg>
					</button>
					<span class="chatbot-header__action-button-tooltip">
						{{ texts.main.tooltipClose }}
					</span>
				</div>
			</div>
		</div>
		<ChatbotConversation
			class="chatbot-body"
			:conversation-history="conversationHistory"
			:function-texts="texts.functions"
			@function-click="emit('function-click', $event)"
		/>
		<slot name="custom-content" />
		<div class="chatbot-base-footer">
			<Transition
				name="slide-top"
				mode="out-in"
				appear
			>
				<span
					v-if="isCharacterCountShown"
					class="chatbot-base-footer__character-count"
				>
					{{ characterCountText }}
				</span>
			</Transition>
			<form
				class="chatbot-base-footer__input-wrapper"
				@submit.prevent="handleMessageSubmit"
			>
				<textarea
					ref="inputRef"
					:value="message"
					class="chatbot-base-footer__input"
					:placeholder="texts.main.questionInputPlaceholder"
					data-qa="ai-assistant-input"
					rows="1"
					:maxlength="CHATBOT_CHAR_LIMIT"
					@click="isInputActive = true"
					@blur="isInputActive = false"
					@input="handleInput"
					@keydown.enter.prevent="handleKeyPressEnter"
					@keydown.delete.stop
				/>
				<button
					type="submit"
					data-qa="ai-assistant-send-button"
					class="chatbot-base-footer__send-button chatbot-button"
					:disabled="isSendButtonDisabled || areActionsDisabled"
				>
					<ChatbotLoader v-if="isResponding" />
					<svg
						v-else
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<mask
							id="mask0_1856_2210"
							style="mask-type:alpha"
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="24"
							height="24"
						>
							<rect
								width="24"
								height="24"
								fill="currentColor"
							/>
						</mask>
						<g mask="url(#mask0_1856_2210)">
							<path
								d="M4.4 19.425C4.06667 19.5583 3.75 19.5291 3.45 19.3375C3.15 19.1458 3 18.8666 3 18.5V14L11 12L3
                9.99997V5.49997C3 5.1333 3.15 4.85414 3.45 4.66247C3.75 4.4708 4.06667 4.44164 4.4 4.57497L19.8
                 11.075C20.2167 11.2583 20.425 11.5666 20.425 12C20.425 12.4333 20.2167 12.7416 19.8 12.925L4.4 19.425Z"
								fill="currentColor"
							/>
						</g>
					</svg>
				</button>
			</form>
			<span
				v-if="texts.main.disclaimer"
				class="chatbot-base-footer__footer-hint"
			>
				{{ texts.main.disclaimer }}
			</span>
		</div>

		<Transition name="fade-slow">
			<ChatbotRestartModal
				v-if="isRestartModalActive && texts.modalRestart"
				:is-restarting="isRestarting"
				:texts="texts.modalRestart"
				:are-actions-disabled="props.areActionsDisabled"
				@on-close="isRestartModalActive = false"
				@on-restart="emit('restart')"
			/>
		</Transition>
	</div>
</template>

<style scoped lang="scss">
$action-button-size: 40px;

.chatbot-button {
  font-family: Arial, sans-serif;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.chatbot-base {
  height: min(100px, 100vh);
  max-height: 800px;
  background: var(--color-light);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: v-bind(chatbotBaseBottomMargin);
  position: relative;
  z-index: 999;
  box-shadow: 0px 4px 12px 0px #00000026;
}

.chatbot-header {
  $this: &;

  display: flex;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;

  &__text {
    color: var(--color-dark);
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__beta {
    color: var(--color-gray);
    text-transform: uppercase;
    background-color: var(--color-gray-light);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
  }

  &__action-button-wrapper {
    position: relative;
  }

  &__action-button-tooltip {
    display: none;
    position: absolute;
    text-wrap: nowrap;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    padding: 0 8px;
    background-color: var(--color-gray);
    font-size: 14px;
    line-height: 1.7;
    color: var(--color-light);
    pointer-events: none;
    top: calc($action-button-size + 4px);
  }

  &__action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: $action-button-size;
    height: $action-button-size;
    border-radius: 8px;

    &:hover {
      background-color: var(--color-gray-light);
    }

    &:hover + .chatbot-header__action-button-tooltip {
      display: block;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.chatbot-base-footer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 8px;
  background-color: var(--color-light);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  &__footer-hint {
    text-align: center;
    padding: 0 10px;
    color: var(--color-gray);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__character-count {
    align-self: flex-end;
    color: var(--color-gray);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--color-light);
    border: 1px solid var(--color-gray-border);
    border-radius: 12px;
    padding: 0 4px 0 8px;
    transition: border-color 0.2s ease-in-out;
    resize: none;
    height: 100%;
    margin-bottom: 8px;

    &:hover,
    &:focus-within {
      border-color: var(--color-gray);
    }
  }

  &__input {
    border: none;
    outline: none;
    width: 100%;
    max-height: 192px;
    padding: 12px 8px;
    font-size: 14px;
    overflow: auto;
    line-height: 1.7;
    color: var(--color-dark);
    background-color: var(--color-light);
    font-family: inherit;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__send-button {
    align-self: flex-end;
    padding: 6px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 4px;
    height: 100%;
    width: 100%;
    max-width: $action-button-size;
    max-height: 40px;
    background-color: var(--color-gray-dark);
	color: var(--color-light);

    &:disabled {
      background-color: var(--color-gray-light);
      color: var(--color-gray);
      cursor: default;
    }

    &:not(:disabled) {
      :deep(.icon) {
        color: var(--color-light);
      }

      &:hover,
      &:focus {
        background-color: var(--color-dark);
        color: var(--color-light);
      }
    }
  }
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 0.3s;
}

.fade-slow-enter,
.fade-slow-leave-to {
  opacity: 0;
}

</style>
