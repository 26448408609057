import { PAGE_TYPE_PRIVATE } from '@zyro-inc/site-modules/constants';

const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const filterLanguageHiddenPages = (
	pages,
	currentDate,
	isAstro = true,
	isPreviewMode = false,
) => Object.fromEntries(
	Object.entries(pages)
		.map(([pageKey, pageValue]) => {
			if (!pageValue.isScheduled) {
				return [
					pageKey,
					pageValue,
				];
			}

			const { date } = pageValue;

			const publishDate = new Date(date).setHours(0, 0, 0, 0);

			const hasCurrentDateExceededPublishDate = publishDate <= currentDate;

			// For Astro scheduled blog posts should be visible in order to generate static pages
			const isScheduledBlogPostVisible = hasCurrentDateExceededPublishDate || isAstro;

			return [
				pageKey,
				{
					...pageValue,
					...(isScheduledBlogPostVisible ? {
						isDraft: false,
					} : {
						isDraft: true,
					}),
				},
			];
		})
		// Omit draft blog pages when building for Astro
		.filter(((entry) => (!entry[1].isDraft || (entry[1].isDraft && isPreviewMode))))
		.filter((entry) => !isEmptyObject(entry[1])),
);

// If private mode is active - returns only private mode page. If not - returns all other pages except private mode.
const languagesAccordingToPrivateMode = ({
	languages,
	isPrivateModeActive,
}) => Object.fromEntries(Object.entries(languages).map(([languageKey, languageData]) => [
	languageKey,
	{
		...languageData,
		pages: Object.fromEntries(Object.entries(languageData.pages).filter(
			([, { type }]) => (isPrivateModeActive ? type === PAGE_TYPE_PRIVATE : type !== PAGE_TYPE_PRIVATE),
		)),
	},
]));

export const filterHiddenPages = ({
	languages,
	currentDate,
	isPrivateModeActive = false,
	isAstro = true,
}) => {
	const pagesAccordingToPrivateMode = languagesAccordingToPrivateMode({
		languages,
		isPrivateModeActive,
	});

	if (isPrivateModeActive) {
		return pagesAccordingToPrivateMode;
	}

	return Object.fromEntries(
		Object.entries(pagesAccordingToPrivateMode).map(([key, value]) => {
			const pages = filterLanguageHiddenPages(value.pages, currentDate, isAstro);

			return [
				key,
				{
					...value,
					pages,
				},
			];
		}),
	);
};
