import type {
	ChatbotConversationHistory,
	ChatbotMessageResponse,
	ChatbotAvailableResponse,
} from '@zyro-inc/site-modules/types';

const ROOT_URL = `${(import.meta as any).env.VITE_BACKEND_API_URL}/site-chatbot`;

export const initializeChatbot = async ({
	siteId,
	conversationId,
	context,
}: {
	siteId: string;
	context: object;
	conversationId?: string;
}): Promise<ChatbotConversationHistory> => {
	const response = await fetch(`${ROOT_URL}/${siteId}/initialize`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			context,
			conversationId,
		}),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new Error(response.statusText || String(response.status), {
			cause: data,
		});
	}

	return data;
};

export const messageChatbot = async ({
	siteId,
	conversationId,
	content,
}: { siteId: string, conversationId: string, content: string }): Promise<ChatbotMessageResponse> => {
	const response = await fetch(`${ROOT_URL}/${siteId}/message/${conversationId}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			content,
		}),
	});

	const data = await response.json();

	if (!response.ok) {
		throw new Error(response.statusText || String(response.status), {
			cause: data,
		});
	}

	return data;
};

export const isChatbotAvailable = async ({ siteId }: { siteId: string }): Promise<ChatbotAvailableResponse> => {
	const response = await fetch(`${ROOT_URL}/${siteId}/is-available`);

	const data = await response.json();

	if (!response.ok) {
		throw new Error(response.statusText || String(response.status), {
			cause: data,
		});
	}

	return data;
};
