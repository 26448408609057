<script setup lang="ts">
import {
	ref,
	watch,
	computed,
	onMounted,
	StyleValue,
} from 'vue';
import { useRouter } from 'vue-router';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import StickyTrigger from '@zyro-inc/site-modules/components/StickyTrigger.vue';
import { scrollToSection } from '@zyro-inc/site-modules/utils/scrollToSection';
import { getGridItemSize } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';

import CookieBanner from '@zyro-inc/site-modules/components/CookieBanner.vue';
import BlockUser from '@zyro-inc/site-modules/components/BlockUser.vue';
import BlockStickyBarProviderUser from '@zyro-inc/site-modules/components/blocks/BlockStickyBarProviderUser.vue';
import PasswordPage from '@zyro-inc/site-modules/components/password-page/PasswordPage.vue';
import EcommerceShoppingCartProviderUser from '@zyro-inc/site-modules/components/ecommerce/EcommerceShoppingCartProviderUser.vue';
import EcommerceModalRoot from '@zyro-inc/site-modules/components/ecommerce/modals/EcommerceModalRoot.vue';
import BlockHeader from '@zyro-inc/site-modules/components/BlockHeader.vue';
import { getHeaderProps } from '@zyro-inc/site-modules/components/blocks/header/getHeaderProps';
import { getIsLocaleWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import Lightbox from '@zyro-inc/site-modules/components/lightbox/Lightbox.vue';
import {
	ELEMENT_POSITION_KEY_DESKTOP,
	ELEMENT_POSITION_KEY_MOBILE,
	SYSTEM_LOCALE,
	BLOCK_STICKY_BAR_ID,
	PAGE_TYPE_PRIVATE,
	DATA_ATTRIBUTE_PREVENT_NATIVE_CLICK,
} from '@zyro-inc/site-modules/constants';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useElementBounding } from '@vueuse/core';
import { SitePage } from '@hostinger/builder-schema-validator';

import AiAssistantChatbot from '@zyro-inc/site-modules/components/ai-assistant-chatbot/AiAssistantChatbot.vue';

type PixelUnit = `${number}px`;

type HeaderHeightStyles = {
	'--header-height'?: PixelUnit;
	'--header-height-mobile'?: PixelUnit;
}

type Props = {
	pageData: Record<string, SitePage>;
	isInPreviewMode?: boolean;
	isPreviewMobileView?: boolean;
}

const props = defineProps<Props>();

const {
	pages,
	blocks,
	elements,
	nav,
	meta,
	metaTitle,
	ecommerceShoppingCart,
	cookieBannerDisclaimer,
	cookieBannerAcceptText,
	cookieBannerDeclineText,
	currentLocale,
	languageSwitcherLanguages,
	siteId,
	homePageId,
	styles,
	isNavHidden,
	currentPageId,
	currentPageData: currentPage,
	getPagePathFromId,
	setPageData,
} = useSiteGlobal();

const {
	isStoreTypeZyro,
	shoppingCartItemCount,
	setShoppingCartOpen,
} = useEcommerceGlobal();

const { isLightboxOpen } = useLightbox();
const router = useRouter();
const { shouldMountAnimationsInPreview } = useSiteEngineAnimations();

const isNavOpen = ref(false);
const blockListRef = ref(null);
const stickyBarHeight = ref(0);

const { width: screenWidth } = useElementBounding(blockListRef);

const currentPageType = computed(() => currentPage.value.type);
const isCurrentPagePrivate = computed(() => currentPageType.value === PAGE_TYPE_PRIVATE);
const pageCSSVars = computed(() => objectToCssVariables(styles.value) as StyleValue);
const isHeaderVisible = computed(() => !isNavHidden.value && !isCurrentPagePrivate.value);
const isStickyBarVisible = computed(() => blocks.value[BLOCK_STICKY_BAR_ID] && !isCurrentPagePrivate.value);
const isCurrentPageHomepage = computed(() => currentPageId.value === homePageId.value);
const homepageName = computed(() => pages.value?.[homePageId.value]?.name ?? '');
const isHeaderSettingsSticky = computed(() => blocks.value.header.settings.isSticky);
const shouldRenderPage = computed(() => !currentPage.value?.meta?.password || props.isInPreviewMode);
const pageBlocksSlotFooter = computed(() => {
	if (!currentPage.value || currentPage.value.footerSlotIsHidden || isCurrentPagePrivate.value) {
		return [];
	}

	const footerBlock = Object.keys(blocks.value).find((blockId) => blocks.value[blockId].slot === 'footer');

	return footerBlock ? [footerBlock] : [];
});

const currentPageBlocks = computed(() => (currentPage.value ? [
	...currentPage.value.blocks.filter((blockId: string) => blockId !== BLOCK_STICKY_BAR_ID),
	...pageBlocksSlotFooter.value,
] : []));

// @ts-ignore until we convert getHeaderProps to ts
const headerProps = computed(() => getHeaderProps({
	siteId: siteId.value,
	meta: meta.value,
	blocks: blocks.value,
	nav: nav.value,
	pages: pages.value,
	elements: elements.value,
	languageMetaTitle: metaTitle.value,
	currentLocale: currentLocale.value,
	currentPageId: currentPageId.value,
	shoppingCartItemCount: shoppingCartItemCount.value,
	languageSwitcherLanguages: languageSwitcherLanguages.value,
	getPagePathFromId: ({ pageId }: { pageId: string }) => getPagePathFromId({
		pageId,
	}),
	isOpen: isNavOpen.value,
}));

const headerHeight = computed(() => headerProps.value.height);
const headerHeightMobile = computed(() => headerProps.value.heightMobile);
const topBarHeightMobilePx = computed(() => `${headerHeightMobile.value + stickyBarHeight.value}px`);
const currentPageBlockData = computed(() => currentPageBlocks.value.map((id) => blocks.value[id]));
const isLocaleWithEcommerceItems = computed(() => getIsLocaleWithEcommerce({
	blocks: blocks.value,
	elements: elements.value,
}));
const defaultLocale = computed(() => meta.value.defaultLocale ?? SYSTEM_LOCALE);
const ecommerceTranslations = computed(() => {
	if (!isStoreTypeZyro.value) {
		return {};
	}

	return ecommerceShoppingCart.value?.translations ?? {};
});
const language = computed(() => {
	if (!isStoreTypeZyro.value) {
		return null;
	}

	return ecommerceShoppingCart.value?.lang ?? 'en';
});
const currentPageEcommerceBlocks = computed(() => {
	if (!isLocaleWithEcommerceItems.value) {
		return [];
	}

	return currentPageBlockData.value.filter((block) => [
		'BlockEcommerceProduct',
		'BlockEcommerceProductList',
	].includes(block.type));
});
const currentPageEcommerceComponents = computed(() => {
	if (!isLocaleWithEcommerceItems.value) {
		return [];
	}

	const allEcommerceComponents = Object.keys(elements.value)?.filter((id) => elements.value[id].type === 'GridEcommerceButton');

	return allEcommerceComponents.filter((id) => currentPageBlockData.value.some((data) => data.components?.includes(id)))
		.map((id) => elements.value[id]);
});

const firstVisibleDesktopBlockId = computed(
	() => currentPageBlocks.value.find((blockId) => !blocks.value[blockId][ELEMENT_POSITION_KEY_DESKTOP]?.isHidden),
);

const firstVisibleMobileBlockId = computed(
	() => currentPageBlocks.value.find((blockId) => !blocks.value[blockId][ELEMENT_POSITION_KEY_MOBILE]?.isHidden),
);

const visibleMobileHeaderHeight = computed((): PixelUnit | undefined => {
	const { isTransparent } = blocks.value.header.background ?? {};

	if (isTransparent && isHeaderVisible.value && headerHeightMobile.value) {
		return `${headerHeightMobile.value}px`;
	}

	return undefined;
});

const visibleDesktopHeaderHeight = computed((): PixelUnit | undefined => {
	const { isTransparent } = blocks.value.header.background ?? {};

	if (isTransparent && isHeaderVisible.value && headerHeight.value) {
		return `${headerHeight.value}px`;
	}

	return undefined;
});

// Return mobile/desktop header heights only if block is first visible
const getHeaderHeightStyles = (blockId: string): HeaderHeightStyles => ({
	...(firstVisibleDesktopBlockId.value === blockId && {
		'--header-height': visibleDesktopHeaderHeight.value,
	}),
	...(firstVisibleMobileBlockId.value === blockId && {
		'--header-height-mobile': visibleMobileHeaderHeight.value,
	}),
});

const lcp = computed(() => {
	const [firstBlockId] = currentPage.value?.blocks ?? [];

	if (blocks.value?.[firstBlockId]?.background?.current === 'image') {
		return {
			type: 'block-background',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockBlogList') {
		return {
			type: 'block-blog-list',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockEcommerceProduct') {
		return {
			type: 'block-ecommerce-product',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockEcommerceProductList') {
		return {
			type: 'block-ecommerce-product-list',
			id: firstBlockId,
		};
	}

	// this should return [{ blockId, elementId }, { blockId, elementId }, ...]
	// because we need both blockId and elementId to get image size
	const allElementIds = currentPageBlocks.value
		.filter((blockId) => blocks.value[blockId]?.components?.length > 0)
		.flatMap((blockId) => blocks.value[blockId].components.map((elementId: string) => ({
			blockId,
			elementId,
		})));

	const largestImage = allElementIds
		.filter(({ elementId }) => elements.value[elementId]?.type === 'GridImage')
		.map(({
			blockId,
			elementId,
		}) => {
			const elementData = elements.value[elementId];

			// Check wether image has 'mobile' or 'desktop' width/height
			// If yes, use them to calculate LCP, otherwise it's ecommerce element and calculate size via `getGridItemSize`
			const sizeInLayout = elementData.mobile ?? elementData.desktop;

			const {
				width,
				height,
			} = sizeInLayout ?? getGridItemSize(
				blocks.value[blockId],
				elementData.settings.styles.position,
			);

			return {
				elementId,
				imageRatio: height / width,
			};
		})[0];

	return largestImage ? {
		type: 'grid-image',
		id: largestImage?.elementId,
	} : {};
});

const scrollToHash = ({
	hash,
	isPreviewMode,
}:{hash: string, isPreviewMode: boolean}) => {
	if (!hash) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	scrollToSection({
		linkToSection: hash,
		isPreviewMode,
	});
};

const handleCartClick = () => {
	if (isLocaleWithEcommerceItems.value) {
		setShoppingCartOpen(true);
	}
};

const redirectToThirdPartyLink = (anchorElement: HTMLAnchorElement) => {
	const {
		target,
		href,
	} = anchorElement;
	const shouldOpenInNewTab = target === '_blank';
	const linkOpenMode = shouldOpenInNewTab || getIsInIframe() || props.isInPreviewMode ? '_blank' : '_self';

	window.open(href, linkOpenMode);
};

const handleGlobalClick = async (event: Event) => {
	if (!event.target) {
		return;
	}

	const closestAnchor = (event.target as HTMLElement).closest('a');

	if (!closestAnchor) {
		return;
	}

	const {
		href,
		pathname,
		origin,
		hash,
		target,
	} = closestAnchor;

	if (!href || closestAnchor.hasAttribute(DATA_ATTRIBUTE_PREVENT_NATIVE_CLICK)) {
		return;
	}

	event.preventDefault();

	const isTargetThirdParty = window.location.origin !== origin;

	if (isTargetThirdParty) {
		redirectToThirdPartyLink(closestAnchor);

		return;
	}

	const isTargetPageCurrentPage = window.location.pathname === pathname;
	const shouldOpenInNewTab = target === '_blank';
	const fullPath = href.replace(origin, '');
	const currentFullPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;

	if (isTargetPageCurrentPage && !shouldOpenInNewTab) {
		scrollToHash({
			hash,
			isPreviewMode: props.isInPreviewMode,
		});
	}

	if ((shouldOpenInNewTab && !props.isInPreviewMode) && !getIsInIframe()) {
		window.open(href, target);
	} else if ((shouldOpenInNewTab && props.isInPreviewMode) || currentFullPath !== fullPath) {
		if (props.isInPreviewMode) {
			router.push(fullPath);
		} else {
			window.location.assign(fullPath);
		}

		if (hash) {
			scrollToHash({
				hash,
				isPreviewMode: props.isInPreviewMode,
			});
		}

		isNavOpen.value = false;
	}
};

// Since `pageData` is set to state during mount, it has to be updated via watcher every time the prop changes.
watch(() => props.pageData, (pageData) => {
	setPageData(pageData);
}, {
	immediate: true,
});

// The first watcher has an `immediate` watcher - so it performs server-side compatible actions
// This watcher has no immediate - so it will run only on the client.
watch(() => props.pageData, async () => {
	if (props.isInPreviewMode) {
		shouldMountAnimationsInPreview.value = true;
	}
});

onMounted(() => {
	stickyBarHeight.value = document.querySelector('.block-sticky-bar')?.clientHeight ?? 0;

	scrollToHash({
		hash: window.location.hash,
		isPreviewMode: props.isInPreviewMode,
	});
});
</script>
<template>
	<main
		v-if="shouldRenderPage"
		:style="pageCSSVars"
		class="page"
		@click="handleGlobalClick"
	>
		<StickyTrigger v-if="isHeaderSettingsSticky" />
		<BlockStickyBarProviderUser
			v-if="!isHeaderSettingsSticky && isStickyBarVisible"
			:data-block-id="BLOCK_STICKY_BAR_ID"
			:data="blocks[BLOCK_STICKY_BAR_ID]"
			:components="elements"
			:lcp="lcp"
		/>
		<div
			class="top-blocks"
			:class="{
				'top-blocks--sticky': headerProps.isSticky,
				'top-blocks--preview-mode': props.isInPreviewMode,
			}"
		>
			<BlockStickyBarProviderUser
				v-if="isHeaderSettingsSticky && isStickyBarVisible"
				:data-block-id="BLOCK_STICKY_BAR_ID"
				:data="blocks[BLOCK_STICKY_BAR_ID]"
				:components="elements"
				:lcp="lcp"
			/>
			<BlockHeader
				v-if="isHeaderVisible"
				v-bind="headerProps"
				:is-in-preview-mode="props.isInPreviewMode"
				:is-preview-mobile-view="isPreviewMobileView"
				:block-sticky-bar="blocks[BLOCK_STICKY_BAR_ID]"
				@toggle-visibility="isNavOpen = !isNavOpen"
				@cart-click="handleCartClick"
			/>
		</div>
		<!-- Simple blocks -->
		<div
			ref="blockListRef"
			class="blocks"
		>
			<BlockUser
				v-for="(blockId) of currentPageBlocks"
				:id="blocks[blockId].htmlId || blockId"
				:key="`${currentPageId}-${blockId}`"
				:data="blocks[blockId]"
				:blocks="blocks"
				:lcp="lcp"
				:page-id="currentPageId"
				:ecommerce-translations="ecommerceTranslations"
				:current-page-type="currentPageType"
				:components="elements"
				:screen-width="screenWidth"
				:style="getHeaderHeightStyles(blockId)"
				:current-locale="currentLocale"
				:is-cart-visible="headerProps.isCartVisible"
				:is-in-preview-mode="props.isInPreviewMode"
				:is-first-visible-desktop-block="firstVisibleDesktopBlockId === blockId"
				:is-first-visible-mobile-block="firstVisibleMobileBlockId === blockId"
			/>
		</div>

		<CookieBanner
			v-if="meta.isCookieBarEnabled && !isCurrentPagePrivate"
			:disclaimer="cookieBannerDisclaimer || ''"
			:accept-text="cookieBannerAcceptText"
			:decline-text="cookieBannerDeclineText"
			:site-meta="meta"
		/>
		<EcommerceShoppingCartProviderUser
			v-if="isLocaleWithEcommerceItems"
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:is-header-sticky="headerProps.isSticky"
			:is-nav-hidden="!isHeaderVisible"
			:current-page-ecommerce-blocks="currentPageEcommerceBlocks"
			:current-page-ecommerce-components="currentPageEcommerceComponents"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
		<EcommerceModalRoot
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:current-locale="currentLocale"
			:is-cart-visible="headerProps.isCartVisible"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
		<AiAssistantChatbot :is-in-preview-mode="props.isInPreviewMode" />
	</main>
	<PasswordPage
		v-else
		:site-id="siteId"
		:page-id="currentPageId"
		:locale="currentLocale"
		:default-locale="defaultLocale"
		:in-preview-mode="isInPreviewMode"
		:current-page-data="currentPage"
		:homepage-name="homepageName"
		:is-current-page-homepage="isCurrentPageHomepage"
	/>
	<Lightbox v-if="isLightboxOpen" />
</template>

<style lang="scss">
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.top-blocks {
	z-index: $z-index-site-engine-header;

	&--sticky {
		position: sticky;
		top: 0;
	}

	&--preview-mode {
		.block-header-layout-mobile__dropdown--open.block-header-layout-mobile__dropdown--full-screen:not(.s) {
			height: calc(793px - v-bind(topBarHeightMobilePx));
		}
	}
}
</style>
