<template>
	<ModalLayout
		ref="modal"
		max-width="905px"
		width="100%"
		padding="0"
		height="auto"
		overflow="auto"
		class="ecommerce-product-preview"
	>
		<div
			v-qa="'productpreview-modal'"
			class="ecommerce-product-preview__content-wrapper"
		>
			<BlockBackground
				v-if="productPreviewData.background"
				:overlay-opacity="productPreviewData.background['overlay-opacity']"
				:type="productPreviewData.background.current"
				:color="productPreviewData.background.color"
				:gradient="productPreviewData.background.gradient"
				:src="backgroundSrc"
				:srcset="backgroundSrcSet"
				:is-fixed="productPreviewData.attachment === 'fixed'"
				:alt="productPreviewData.background && productPreviewData.background.alt"
				:video-poster="productPreviewData.background.video?.videoThumbnailSrc"
				class="ecommerce-product-preview__background"
			/>
			<BlockEcommerceProductProviderUser
				class="ecommerce-product-preview__content"
				:is-quick-preview="isQuickPreview"
				:data="productPreviewData"
				:product-pages="productPages"
				:is-cart-visible="isCartVisible"
				:ecommerce-translations="translations"
			/>
		</div>
	</ModalLayout>
</template>

<script setup>
import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import ModalLayout from '@zyro-inc/site-modules/components/ecommerce/modals/partials/ModalLayout.vue';
import BlockEcommerceProductProviderUser from '@zyro-inc/site-modules/components/blocks/BlockEcommerceProductProviderUser.vue';
import { computed } from 'vue';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';

import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';

import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

const props = defineProps({
	translations: {
		type: Object,
		default: () => {},
	},
	isQuickPreview: {
		type: Boolean,
		default: false,
	},
	currentLocale: {
		type: String,
		required: true,
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
});

const { productPreviewData } = useEcommerceModal(props);

const { siteId } = useSiteGlobal();
const { productPages } = useEcommerceGlobal();

const backgroundSrc = computed(() => getOptimizedSrc(
	productPreviewData.value.background.origin,
	productPreviewData.value.background.path,
	siteId.value,
	{
		width: FULL_WIDTH,
	},
));
const backgroundSrcSet = computed(
	() => getFullWidthSrcset(
		productPreviewData.value.background.origin,
		productPreviewData.value.background.path,
		siteId.value,
		{
			isMobileFullScreen: false,
		},
	),
);
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.ecommerce-product-preview {
	z-index: $z-index-site-engine-ecommerce-preview;

	&__content-wrapper {
		position: relative;
		height: 100%;
		max-height: calc(100vh - 48px);
	}

	&__content {
		:deep(.block-product) {
			max-height: 700px;
			padding: 32px;
		}
	}

	&__background {
		z-index: -1;
		height: 100%;
	}
}

@include site-engine-mobile {
	.ecommerce-product-preview {
		&__content-wrapper {
			height: auto;
			min-height: unset;
		}

		&__content {
			:deep(.block-product) {
				max-height: unset;
			}
		}
	}
}
</style>
