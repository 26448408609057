<template>
	<div class="chatbot-loader">
		<div class="chatbot-loader__element" />
		<div class="chatbot-loader__element" />
		<div class="chatbot-loader__element" />
		<div class="chatbot-loader__element" />
		<div class="chatbot-loader__element" />
	</div>
</template>

<style lang="scss" scoped>
.chatbot-loader {
  position: relative;
  width: 20px;
  height: 20px;

  &__element {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 4px solid;
    border-color: var(--color-gray) transparent transparent;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &__element:nth-child(1) {
    animation-delay: -0.45s;
  }

  &__element:nth-child(2) {
    animation-delay: -0.3s;
  }

  &__element:nth-child(3) {
    animation-delay: -0.15s;
  }

  &__element:nth-child(4) {
    border-color: var(--color-gray);
    opacity: 0.2;
    animation: none;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
