import { EcommerceCurrency } from '@zyro-inc/site-modules/types';

export function formatPrice({
	amount,
	currency,
	isPriceDisplayedWithCurrency = true,
}: {
	amount?: number | string,
	currency?: {template: string, decimal_digits: number} | EcommerceCurrency,
	isPriceDisplayedWithCurrency?: boolean
}) {
	if (!currency) {
		return isPriceDisplayedWithCurrency ? '€0.00' : '0.00';
	}

	const number = Number(amount) || 0;
	const adjustedAmount = Number(number / (10 ** currency.decimal_digits));

	const price = adjustedAmount.toFixed(currency.decimal_digits);

	return isPriceDisplayedWithCurrency ? currency.template.replace('$1', price) : price;
}
