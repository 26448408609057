<script setup lang="ts">
import { marked } from 'marked';
import { computed } from 'vue';

import type { ChatbotMessageFunction } from '@zyro-inc/site-modules/types';

type Props = {
  message?: string;
  functions?: ChatbotMessageFunction[];
  functionTexts?: Record<string, string>;
};

const emit = defineEmits<{
  'function-click': [
    { name: string; arguments: Record<string, string> | undefined },
  ];
}>();

const props = withDefaults(defineProps<Props>(), {
	message: '',
});

const markedRenderer = new marked.Renderer();

// Setup marked lib to open links in a new tab
markedRenderer.link = (href, title, text) => `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
marked.use({
	renderer: markedRenderer,
});

const messageMarkDown = computed(() => marked.parse(props.message));
const functionButtonName = computed(
	() => props.functions?.[0] && props.functionTexts?.[props.functions[0].name],
);
</script>

<template>
	<div
		data-qa="chatbot-message"
		class="chatbot-message"
	>
		<div class="chatbot-message__icon">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<rect
					width="24"
					height="24"
					rx="6"
					fill="currentColor"
				/>
				<path
					d="M17.5239 8.89094L18.1379 7.53073L19.4981 6.91669C19.8013 6.77678 19.8013 6.34928 19.4981 6.20938L18.1379
           5.59534L17.5239 4.22735C17.384 3.92422 16.9565 3.92422 16.8166 4.22735L16.2025 5.58756L14.8345 6.2016C14.5314
           6.34151 14.5314 6.76901 14.8345 6.90891L16.1948 7.52295L16.8088 8.89094C16.9487 9.19407 17.384 9.19407 17.5239
            8.89094ZM11.3368 10.0568L10.101 7.33641C9.82895 6.73014 8.95841 6.73014 8.68637 7.33641L7.45052 10.0568L4.73009
            11.2927C4.12382 11.5725 4.12382 12.4353 4.73009 12.7073L7.45052 13.9432L8.68637 16.6636C8.96619 17.2699 9.82895
             17.2699 10.101 16.6636L11.3368 13.9432L14.0573 12.7073C14.6635 12.4275 14.6635 11.5647 14.0573 11.2927L11.3368
             10.0568ZM16.8088 15.1091L16.1948 16.4693L14.8345 17.0833C14.5314 17.2232 14.5314 17.6507 14.8345 17.7906L16.1948
             18.4047L16.8088 19.7726C16.9487 20.0758 17.3762 20.0758 17.5161 19.7726L18.1301 18.4124L19.4981
              17.7984C19.8013 17.6585 19.8013 17.231 19.4981 17.0911L18.1379 16.477L17.5239 15.1091C17.384
               14.8059 16.9487 14.8059 16.8088 15.1091Z"
					fill="#fff"
				/>
			</svg>
		</div>
		<div v-if="message">
			<div
				class="chatbot-message__content"
				v-html="messageMarkDown"
			/>
			<button
				v-if="functionButtonName"
				class="chatbot-message__function-button"
				data-qa="aiassistant-function-button-callfunction"
				@click="
					emit('function-click', {
						name: props.functions![0].name,
						arguments: props.functions![0].arguments,
					})
				"
			>
				{{ functionButtonName }}
			</button>
		</div>
		<div
			v-else
			class="chatbot-message__dots-wrapper"
		>
			<div class="chatbot-message__moving-dot" />
			<div class="chatbot-message__moving-dot" />
			<div class="chatbot-message__moving-dot" />
		</div>
	</div>
</template>

<style lang="scss">

</style>
<style lang="scss" scoped>
.chatbot-message {
  $this: &;

  :deep {
    // used for welcome message sub text note
    .chatbot-message__note {
      color: var(--color-gray) !important;
      font-size: 12px;
      line-height: 20px;
    }
  }

  display: flex;
  align-items: flex-start;
  padding: 16px;
  line-height: 1.7;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--color-gray-light);
  border-radius: 12px;

  &__icon {
    color: var(--color-gray-dark);
    margin-right: 16px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  &__dots-wrapper {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  &__moving-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color-gray);
    animation: moving-dot 1.2s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: 0.2s;
    }

    &:nth-child(2) {
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }

    @keyframes moving-dot {
      0%,
      80%,
      100% {
        transform: scale(0.6);
      }

      40% {
        transform: scale(1);
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__function-button {
    font-family: Arial, sans-serif;
    margin-top: 8px;
    padding: 8px 16px;
    background-color: transparent;
    color: var(--color-dark);
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.2s;
    border: 1px solid var(--color-gray-border);
    font-weight: 700;

    &:hover {
      color: var(--color-gray);
    }
  }
}

:deep(.chatbot-message__content) {
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  pre {
    white-space: break-spaces;
  }

  pre:has(code) {
    background-color: var(--gray-dark);
    border-radius: 8px;
    padding: 8px 16px;
    color: var(--color-light);
  }

  code {
    font-size: 12px;
    background: none;
    overflow-wrap: anywhere;
  }

  a {
    text-decoration: underline;
  }

  p {
    color: var(--color-dark);
    margin: 0;
  }

  p:has(a) {
    overflow-wrap: anywhere;
  }
}
</style>
