<template>
	<div
		:id="id"
		v-qa="'builder-gridelement-gridembed'"
		class="grid-embed"
	>
		<iframe
			v-if="shouldRender"
			ref="gridEmbedIframeRef"
			class="grid-embed__iframe"
			:srcdoc="srcdoc"
			title="custom code element"
			@load="observeGridEmbed"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
	watch,
} from 'vue';

type Props = {
	srcdoc: string,
	shouldRender: boolean,
	height: number | null,
	id: string,
}

const props = withDefaults(defineProps<Props>(), {
	srcdoc: '',
	shouldRender: false,
	height: null,
	heightMobile: null,
});

const emit = defineEmits(['iframe-height-updated']);

const isObserverRunning = ref(false);
const observerInterval = ref();
const observedHeight = ref(0);
const gridEmbedIframeRef = ref<HTMLIFrameElement>();

/**
 * If `observedHeight` becomes bigger (for ex., navigation occurs in Embed), prefer it over original size.
 * Method is used to reduce duplication for desktop/mobile handling.
*/
const getCurrentEmbedHeight = (heightToCompare: number | null) : number => {
	if (!heightToCompare) {
		return observedHeight.value;
	}

	return observedHeight.value > heightToCompare ? observedHeight.value : heightToCompare;
};

const height = computed(() => `${getCurrentEmbedHeight(props.height)}px`);
const heightMobile = computed(() => `${getCurrentEmbedHeight(props.heightMobile)}px`);

const observeGridEmbed = () => {
	if (isObserverRunning.value) return;

	isObserverRunning.value = true;

	const iframeHtml = gridEmbedIframeRef.value?.contentWindow?.document.querySelector('html') as HTMLElement;

	const heightObserver = new ResizeObserver(([{ contentRect }]) => {
		requestAnimationFrame(() => {
			if (contentRect.height !== observedHeight.value) {
				observedHeight.value = contentRect.height;
				emit('iframe-height-updated', observedHeight.value);
			}
		});
	});

	heightObserver.observe(iframeHtml);
};

// safari doesn't fire iframe with srcdoc load event so we need to trigger observer manually
// 1500ms was chosen as a reasonable interval for checking iframe state
watch(() => props.shouldRender, (shouldRender) => {
	if (shouldRender && !isObserverRunning.value) {
		observerInterval.value = setInterval(() => {
			const iframeBody = gridEmbedIframeRef.value?.contentWindow?.document.querySelector('body') as HTMLElement;
			const isIframeFilled = !!iframeBody.children.length;

			if (isObserverRunning.value) {
				clearInterval(observerInterval.value);

				return;
			}

			if (isIframeFilled) {
				observeGridEmbed();

				clearInterval(observerInterval.value);
			}
		}, 1500);
	}
});

</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-embed {
	width: 100%;
	height: auto;

	&__iframe {
		width: 100%;
		height: v-bind(height);
		overflow: hidden;
		border: none;
	}
}

@include site-engine-mobile {
	.grid-embed {
		&__iframe {
			height: v-bind(heightMobile);
		}
	}
}
</style>
