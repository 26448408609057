import {
	ref,
	onMounted,
} from 'vue';
import { CHECKOUT_ERROR_CODES } from '@zyro-inc/site-modules/constants/ecommerce';
import { EcommerceProductType } from '@zyro-inc/site-modules/types';

import { getIsSameCustomField } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';

import { getCheckoutUrl } from '@zyro-inc/site-modules/api/StoreApi';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

export const getExistingProductWithCustomField = (quantifiedProducts, productToMatch) => quantifiedProducts.find(
	(searchedProduct) => {
		const isSameProduct = searchedProduct.variant_id === productToMatch.variants[0].id;

		if (searchedProduct.custom_field_values?.length) {
			const isSameCustomField = searchedProduct.custom_field_values.some((field) => (
				productToMatch.custom_field_values?.some((customField) => (
					getIsSameCustomField(field, customField)
				))));

			return isSameProduct && isSameCustomField;
		}

		return isSameProduct;
	},
);
export const prepareCartItemsForCheckoutInitiation = (cartItems) => cartItems.map((item) => {
	const productClone = {
		...item,
	};

	if (productClone.custom_fields?.length) {
		delete productClone.custom_fields;

		return {
			...productClone,
			custom_field_values: item.custom_fields.map((field) => ({
				custom_field_id: field.id,
				value: field.value || '',
			})),
		};
	}

	return productClone;
});

export const getQuantifiedCartItems = (cartItems) => cartItems.reduce((quantifiedProducts, product) => {
	const existingProduct = getExistingProductWithCustomField(quantifiedProducts, product);

	if (existingProduct) {
		return quantifiedProducts.map((productToUpdate) => {
			const isSameProduct = productToUpdate.variant_id === product.variants[0].id;
			const isSameCustomFields = productToUpdate.custom_field_values?.every(
				(field) => product.custom_field_values.some(
					(productField) => productField.id === field.id && productField.value === field.value,
				),
			) ?? true;

			if (isSameProduct && isSameCustomFields) {
				return {
					variant_id: productToUpdate.variant_id,
					quantity: productToUpdate.quantity + 1,
					custom_field_values: productToUpdate.custom_field_values,
				};
			}

			return productToUpdate;
		});
	}

	return [
		...quantifiedProducts,
		{
			custom_field_values: product.custom_field_values,
			variant_id: product.variants[0].id,
			quantity: 1,
			time_slot: product.variants[0].booking_event?.time_slot,
			time_zone: product.variants[0].booking_event?.time_zone,
		},
	];
}, []);

export const useEcommerce = () => {
	const { openEcommerceModal } = useEcommerceModal();
	const {
		meta,
		ecommerceShoppingCart,
		isSiteEngine,
	} = useSiteGlobal();

	let storeId = '';
	const cancelUrl = ref('');

	onMounted(() => {
		storeId = meta.value?.ecommerceStoreId;
		cancelUrl.value = `${window.location.origin}${window.location.pathname}`;
	});

	function successUrl(products) {
		const isProductDonation = products.some((product) => product.type.value === EcommerceProductType.DONATION);
		const isProductBooking = products.some((product) => product.type.value === EcommerceProductType.BOOKING);
		let productTypeQuery = '';

		if (isProductBooking) {
			productTypeQuery = `&product=${EcommerceProductType.BOOKING}`;
		} else if (isProductDonation) {
			productTypeQuery = `&product=${EcommerceProductType.DONATION}`;
		}

		return `${cancelUrl.value}?open-modal=EcommerceCheckoutSuccess${productTypeQuery}`;
	}

	async function createCheckoutUrl(checkoutItems) {
		const updatedCheckoutItems = prepareCartItemsForCheckoutInitiation(checkoutItems);
		const quantifiedProductList = getQuantifiedCartItems(updatedCheckoutItems);

		const locale = ecommerceShoppingCart.value?.lang || 'en';

		let checkoutUrl = await getCheckoutUrl({
			items: quantifiedProductList,
			cancelUrl: cancelUrl.value,
			successUrl: successUrl(updatedCheckoutItems),
			...(!isSiteEngine.value && {
				checkoutUrl: `${window.location.origin}/checkout`,
			}),
			locale,
			storeId,
		});

		checkoutUrl = new URL(checkoutUrl);
		checkoutUrl.searchParams.set('lang', locale);

		return checkoutUrl.toString();
	}

	async function initiateCheckout(checkoutItems) {
		if (!storeId) {
			openEcommerceModal('EcommerceCheckoutFailed');

			return;
		}

		try {
			const checkoutUrl = await createCheckoutUrl(checkoutItems);

			window.location.assign(checkoutUrl);
		} catch (error) {
			if (error.code === CHECKOUT_ERROR_CODES.INSUFFICIENT_INVENTORY) {
				openEcommerceModal('EcommerceOutOfStock');
			} else if (error.code === CHECKOUT_ERROR_CODES.BOOKING_UNAVAILABLE) {
				openEcommerceModal('EcommerceBookingUnavailable');
			} else {
				openEcommerceModal('EcommerceCheckoutFailed');
			}
		}
	}

	return {
		initiateCheckout,
		storeId,
		cancelUrl,
		createCheckoutUrl,
	};
};
