export const DEFAULT_GRID_CONTENT_WIDTH = 1600;
export const DEFAULT_GRID_WIDTH = 1224;
export const DEFAULT_GRID_COLUMN_COUNT = 12;
export const DEFAULT_GRID_CONTENT_PADDING = 16;
export const DEFAULT_BLOCK_BLOG_COLUMN_GAP = 32;
export const DEFAULT_BLOCK_BLOG_M_COLUMN_GAP = 8;

export const DEFAULT_BLOCK_BLOG_STYLES = {
	'--column-gap': `${DEFAULT_BLOCK_BLOG_COLUMN_GAP}px`,
	'--m-column-gap': `${DEFAULT_BLOCK_BLOG_M_COLUMN_GAP}px`,
};

export const DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH = 1224;

export const DEFAULT_BLOG_CONTENT_WIDTH = 1224;
