export const fetchSiteData = async () => {
	if (import.meta.env.DEV) {
		const playgroundSiteData = await import('@zyro-inc/site-modules/templates/main.json');

		return playgroundSiteData;
	}

	const response = await fetch(`${window.location.origin}/data.json`);
	const siteData = await response.json();

	return siteData;
};
