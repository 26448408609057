<template>
	<div />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { getIsSiteWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';

const { website } = useSiteGlobal();
const route = useRoute();

const redirectToCheckoutApp = () => {
	const cartId = route.query.cart_id as string;
	const lang = route.query.lang as string;

	if (!cartId) {
		window.location.href = import.meta.env.VITE_ECOMMERCE_CHECKOUT_URL;

		return;
	}

	const redirectUrl = `${import.meta.env.VITE_ECOMMERCE_CHECKOUT_URL}?cart_id=${cartId}${lang ? `&lang=${lang}` : ''}`;

	window.location.href = redirectUrl;
};

const handleRedirect = () => {
	const isSiteWithEcommerce = getIsSiteWithEcommerce(website.value);

	if (!isSiteWithEcommerce) {
		window.location.href = '/';

		return;
	}

	redirectToCheckoutApp();
};

handleRedirect();
</script>
