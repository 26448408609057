export const getWebsiteCategoryUrl = (categoryId: string) => {
	const newUrl = new URL(window.location.href);

	if (categoryId) {
		newUrl.searchParams.set('category', categoryId);
	} else {
		newUrl.searchParams.delete('category');
	}

	return newUrl.href;
};
