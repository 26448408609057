import {
	TOP_BLOCK_CLASS,
	TOP_BLOCK_STICKY_CLASS,
	SITE_PREVIEW_CLASS,
} from '@zyro-inc/site-modules/constants';

export const scrollToSection = ({
	linkToSection,
	isInstant = false,
	isPreviewMode = false,
} : {
	linkToSection: string;
	isInstant?: boolean;
	isPreviewMode?: boolean;
}) => {
	// Previously used `querySelector` does not support section ids that start with a number:
	// "querySelector method uses CSS3 selectors for querying the DOM.
	// They cannot start with a digit, two hyphens, or a hyphen followed by a digit."
	const element = document.getElementById(linkToSection.replace('#', ''));

	// If section is not found, do not scroll.
	if (!element) {
		return;
	}

	const { offsetTop: elementTop } = element;

	// Fallback to 0 when all navigation items are hidden and header is not visible
	const headerHeight = document.querySelector<HTMLElement>(`.${TOP_BLOCK_CLASS}`)?.offsetHeight || 0;
	const isHeaderSticky = document.querySelector<HTMLElement>(`.${TOP_BLOCK_STICKY_CLASS}`);
	const previewContainer = document.querySelector(`.${SITE_PREVIEW_CLASS}`);

	const top = isHeaderSticky ? elementTop - headerHeight : elementTop;
	const behavior = isInstant ? 'instant' : 'smooth';

	// requestAnimationFrame solves Safari scroll not running if elements change e.g. less blog posts in the list then in the previous page
	window.requestAnimationFrame(() => {
		if (isPreviewMode && previewContainer) {
			previewContainer.scrollTo({
				top,
				behavior,
			});
		} else {
			window.scrollTo({
				top,
				behavior,
			});
		}
	});
};
