<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

const emit = defineEmits<{
  close: [];
}>();

const modal = ref(null);

onClickOutside(modal, (event: PointerEvent) => {
	if ((event?.target as HTMLElement)?.className !== 'chatbot-modal') {
		return;
	}

	emit('close');
});
</script>

<template>
	<div class="chatbot-modal">
		<div
			ref="modal"
			class="chatbot-modal__content"
		>
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.chatbot-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,30%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    border-radius: 8px;
    background-color: var(--color-light);
    width: calc(100% - 16px);
  }
}
</style>
