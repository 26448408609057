<script setup lang="ts">
import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';
import { EcommerceProductCustomField } from '@zyro-inc/site-modules/types';

const MESSAGE_MAX_LENTH = 700;

type Props = {
	customField: EcommerceProductCustomField,
	translations: Record<string, string>,
	isButtonClicked?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	customField: () => ({} as EcommerceProductCustomField),
	translations: () => ({}),
});
const emit = defineEmits<{
	'update:modelValue': [{message: string, errors: number}],
}>();

const isInputDirty = ref(false);
const message = ref('');

const errorMessages = computed(() => {
	const errors: string[] = [];

	if (!isInputDirty.value) {
		return errors;
	}

	if (props.customField.is_required && !message.value) {
		errors.push(props.translations.textIsRequired);
	}

	if (message.value?.length > MESSAGE_MAX_LENTH) {
		errors.push(props.translations.textIsTooLong);
	}

	return errors;
});

const fieldId = computed(() => props.customField.title.toLowerCase().replace(/\s/g, '-'));

const handleInputChange = (event: Event) => {
	isInputDirty.value = true;

	message.value = (event.target as HTMLInputElement).value;

	emit('update:modelValue', {
		message: message.value,
		errors: errorMessages.value.length,
	});
};

onMounted(() => {
	message.value = props.customField.value || '';
});

watch(() => props.isButtonClicked, (isButtonClicked) => {
	if (isButtonClicked && !isInputDirty.value) {
		isInputDirty.value = true;
	}
});

</script>

<template>
	<div class="custom-field">
		<label :for="fieldId">
			{{ customField.title }}
		</label>
		<input
			:id="fieldId"
			class="custom-field__input"
			:value="message"
			@input="handleInputChange"
		>
		<div
			class="custom-field__error"
			:class="{ 'custom-field__error--visible': errorMessages.length > 0 }"
		>
			{{ errorMessages[0] || '' }}
		</div>
	</div>
</template>

<style scoped lang="scss">
$color-error-ecommerce: #ff5252;

.custom-field {
	width: 100%;
	max-width: 340px;
	color: inherit;

	&__input {
		width: 100%;
		padding: 10px 16px;
		font-family: inherit;
		font-size: inherit;
		line-height: 1.5;
		color: var(--body-color, $color-dark);
		background-color: $color-light;
		border: 0.5px solid $color-gray-border;
		margin: 8px 0 6px;
		text-overflow: ellipsis;
	}

	&__error {
		font-size: 12px;
		line-height: 1;
		color: $color-error-ecommerce;
		visibility: hidden;
		height: 1em;

		&--visible {
			visibility: visible;
			height: auto;
		}
	}
}
</style>
