import {
	EcommerceCurrency,
	EcommerceVariantPrice,
	EcommerceProductType,
} from '@zyro-inc/site-modules/types/index';
import { ECOMERCE_PRODUCTS_FREE_TYPES } from '@zyro-inc/site-modules/constants/ecommerce';

const sanitizeValue = (value: any = ''): any => {
	if (typeof value !== 'string') {
		return value;
	}

	return value.replace(/,/g, '.').trim();
};

const isNumber = (amount: string | number = '', toDecimal = 3): boolean => {
	const regexDigitsCheck = toDecimal === 0 ? '' : `\\.\\d{0,${toDecimal}}`;

	return new RegExp(`^\\d+(${regexDigitsCheck})?$`).test(String(amount));
};

const isValidPrice = (currency: EcommerceCurrency, amount: number | string = ''): boolean => isNumber(amount, currency.decimal_digits);

const convertToHumanFormat = (amount: any, currency: EcommerceCurrency): any => {
	const sanitized = sanitizeValue(amount);

	if (isValidPrice(currency, sanitized)) {
		return Number((sanitized / (10 ** currency.decimal_digits)).toFixed(currency.decimal_digits));
	}

	return amount;
};

const getTextFromCurrency = (amount: any, currency: EcommerceCurrency): string => {
	const number = Number(amount) || 0;
	const adjustedAmount = convertToHumanFormat(number, currency);

	return currency.template.replace('$1', adjustedAmount.toFixed(currency.decimal_digits));
};

export const getVariantPrice = (price: EcommerceVariantPrice, productType?: EcommerceProductType): string => {
	if (!price || !price.currency) return '—';

	const amount = price.sale_amount ? Math.min(price.amount, price.sale_amount) : price.amount;

	if (amount === 0) {
		if (productType && ECOMERCE_PRODUCTS_FREE_TYPES.includes(productType)) {
			return 'Free';
		}

		return '—';
	}

	return getTextFromCurrency(amount, price.currency);
};
