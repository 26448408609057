import {
	createRouter,
	createWebHistory,
} from 'vue-router';

import PageWrapper from '@zyro-inc/site-modules/components/PageWrapper.vue';
import Checkout from '@/Checkout.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: PageWrapper,
		},
		{
			path: '/:slug',
			component: PageWrapper,
		},
		{
			path: '/:lang/:slug',
			component: PageWrapper,
		},
		{
			path: '/checkout',
			component: Checkout,
		},
	],
	// If there is a saved already position - use it.
	// If no hash is provided - scroll to top
	// If hash is provided - scroll to the section (and account for header height)
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition && !to.hash) {
			return savedPosition;
		}

		if (!to.hash) {
			return {
				top: 0,
				left: 0,
			};
		}

		return {
			el: to.hash,
			top: 0,
			left: 0,
			behavior: 'smooth',
		};
	},
});

// Make google analytics pageview work with SinglePageApplications
// https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
router.afterEach((to) => {
	if (window.gtag) {
		window.gtag('set', 'page_path', to.path);
		window.gtag('event', 'page_view');
	}
});

export default router;
