<script setup lang="ts">
import {
	computed,
	onMounted,
	watch,
	ref,
} from 'vue';

import ChatbotMessage from '@zyro-inc/site-modules/components/chatbot/ChatbotMessage.vue';
import ChatbotQuestion from '@zyro-inc/site-modules/components/chatbot/ChatbotQuestion.vue';
import ChatbotSystemMessage from '@zyro-inc/site-modules/components/chatbot/ChatbotSystemMessage.vue';
import type { ChatbotConversationMessage } from '@zyro-inc/site-modules/types';
import { ChatbotRoles } from '@zyro-inc/site-modules/types';

const SCROLL_DELAY = 100;

type Props = {
  conversationHistory: ChatbotConversationMessage[];
  functionTexts?: Record<string, string>;
};

const emit = defineEmits<{
  'function-click': [
    { name: string; arguments: Record<string, string> | undefined },
  ];
}>();

const props = defineProps<Props>();
const conversationRef = ref<HTMLElement | null>(null);
const conversationHistory = computed(() => props.conversationHistory);

const scrollToBottom = (isInstant = false) => {
	if (conversationRef.value?.scrollTo) {
		conversationRef.value.scrollTo({
			top: conversationRef.value.scrollHeight,
			behavior: isInstant ? 'auto' : 'smooth',
		});
	}
};

onMounted(() => {
	scrollToBottom(true);
});

watch(conversationHistory, () => {
	setTimeout(() => {
		scrollToBottom();
	}, SCROLL_DELAY);
});

</script>

<template>
	<div
		ref="conversationRef"
		class="chatbot-conversation-container"
	>
		<template
			v-for="({ role, content, functions }, index) in conversationHistory"
			:key="index"
		>
			<ChatbotMessage
				v-if="role === ChatbotRoles.assistant"
				:message="content"
				:functions="functions"
				:function-texts="functionTexts"
				@function-click="emit('function-click', $event)"
			/>
			<ChatbotQuestion
				v-if="role === ChatbotRoles.user"
				:message="content"
			/>
			<ChatbotSystemMessage
				v-if="role === ChatbotRoles.system"
				:message="content"
			/>
		</template>
	</div>
</template>

<style scoped lang="scss">
.chatbot-conversation-container {
  height: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 0 8px 8px;
  gap: 8px;
}
</style>
